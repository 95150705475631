
import "./App.css";
import LeafletMap from "./components/LeafletMap.js";
// import L from 'leaflet'
import Notification from "./components/Notification.js";


// L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/'


const App = () => {

  let lat = 36.086798;
  let lon = 140.105651;
  let acc = 0;
  let bool = false;

  let pt = [lat, lon];
  //const pt = [36.086798, 140.105651];

  return (
    <div className="App">
      <LeafletMap pt={pt} />
      <Notification />
    </div>
  );
};

export default App;