// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet'
import { MapContainer, TileLayer, Polygon, Marker, Popup } from 'react-leaflet';
// import Data from './tsukuba.geojson';


// const LeafletMap = (pt) => {
  const LeafletMap = () => {
  const multiPolygon = [
    [
      [36.10605097754877, 140.10086408169022],
      [36.096385425461676, 140.10245194944167],
      [36.09424410642498, 140.09819260149752],
      [36.084256293119644, 140.105456023521],
      [36.084689818333295, 140.1078700116408],
      [36.086207137635, 140.10842791111978],
      [36.087464322917995, 140.11108866246155],
      [36.093013038326774, 140.1070653489572],
      [36.09887344358569, 140.10576715978536],
      [36.099358901333424, 140.11027327092935],
      [36.105504883878005, 140.10906091244124],
      [36.104308665445984, 140.10428658040317],
      [36.10467273385075, 140.10331025631496],
      [36.10658839937033, 140.10250559361106],
      [36.10605097754877, 140.10086408169022]
    ],
    [
      [36.08306286469238, 140.10908931407198],
      [36.08188364620969, 140.1099261632904],
      [36.08268135476575, 140.11184662495833],
      [36.08377385552481, 140.11092394505084],
      [36.08306286469238, 140.10908931407198]
    ],
  ]
   
  // pt = [36.086798, 140.105651];

  // console.log(pt);
  return (
    <MapContainer center={[36.086798, 140.105651]} zoom={15}>
    <TileLayer 
      url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
      attribution="<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>"
    />
    {/* <Marker position={pt} ></Marker> */}
    <Polygon pathOptions={{ color: 'red' }} positions={multiPolygon}></Polygon>

    </MapContainer>
  );
};

export default LeafletMap;
